<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import {mapState} from "vuex";


export default {
  page: {
    title: "Server",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout
  },
  validations: {

  },
  methods: {

  },
  created() {

  },
  data() {
    return {

    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
      <div class="col justify-content-center">
        <div  class="info-component text-center align-middle mt-auto mb-auto">
          <div class="info-component-space">
            <i class="fas fa-do-not-enter text-danger info-component-icon"></i>
          </div>
          <div class="row" style="margin-top: 20px;">
            <div class="col-lg-12">
              <h3 class="text-uppercase text-danger">{{$t('server.restricted.title')}}</h3>
              <h5 class="text-white">{{$t('server.restricted.message')}}</h5>
              <h6 class="text-muted">{{$t('server.restricted.details')}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>